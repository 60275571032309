import MuiSnackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { forwardRef, useCallback } from "react";
import { useAtom } from "jotai";
import { Grow, GrowProps } from "@mui/material";

import { snackbarAtom } from "../../Atom";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionUp(props: GrowProps) {
  return <Grow {...props} />;
}

export function Snackbar() {
  const [snackbarData, setSnackbarData] = useAtom(snackbarAtom);
  const {
    message,
    openSnackbar,
    severity,
    horizontalPosition,
    verticalPosition,
  } = snackbarData;

  const handleClose = useCallback(
    (event?: any, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackbarData({
        ...snackbarData,
        openSnackbar: false,
        message,
        severity,
      });
    },
    [message, setSnackbarData, severity, snackbarData]
  );

  return (
    <MuiSnackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionComponent={TransitionUp}
      anchorOrigin={{
        vertical: verticalPosition || "bottom",
        horizontal: horizontalPosition || "left",
      }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
}
