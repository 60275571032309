import { useReactOidc } from "@axa-fr/react-oidc-context";

export function useClaims(serviceName: string) {
  const { oidcUser } = useReactOidc();
  const { profile } = oidcUser;
  const { role } = profile;
  if (!serviceName || (role && role.toLowerCase() === "super admin"))
    return "write";
  return profile[serviceName] as string;
}
