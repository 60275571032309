import React, { Suspense } from "react";
import { AuthorizedRoute } from "./components/Admin/AuthorizedRoute";
import { Camelize } from "./components/Utils/stringUtils";

type WithSuspenseProp = {
  children: React.ReactNode;
  /** for authorization */
  serviceName?: string;
};

export function WithSuspense(props: WithSuspenseProp) {
  const { children, serviceName } = props;
  return (
    // not adding spinner as fallback as it causes flicker, bad user experience
    <Suspense fallback={null}>
      {serviceName ? (
        <AuthorizedRoute serviceName={Camelize(serviceName)}>
          {children}
        </AuthorizedRoute>
      ) : (
        children
      )}
    </Suspense>
  );
}
