import { DropdownItem } from "./Input/Dropdown/Dropdown";

export const IDENTITY_CLIENT = "identity";
export const SERVER_DATE_FORMAT = "dd/M/yyyy";
export const ADMIN_URI = "/admin";

export const CONTAINER_TYPE_OPTIONS: DropdownItem[] = [
  { name: "None", value: "0" },
  { name: "Tanker", value: "1" },
  { name: "Drum", value: "2" },
  { name: "Bag", value: "4" },
];

export const BUSINESS_TYPE_OPTIONS: DropdownItem[] = [
  { name: "None", value: "0" },
  { name: "Trader", value: "1" },
  { name: "Manufactuer", value: "2" },
  { name: "Consumer", value: "4" },
  { name: "Distalliation", value: "8" },
  { name: "Importer", value: "16" },
];

export const USER_ROLE = "User";
export const ADMIN_ROLE = "Admin";
export const SUPER_ADMIN_ROLE = "Super Admin";

export const ADMIN_ROLES = [SUPER_ADMIN_ROLE, ADMIN_ROLE];

export const ALL_ROLES = [...ADMIN_ROLES, USER_ROLE];

export const USER_MANAGEMENT_SERVICE = "User Management";
export const MESSAGES_SERVICE = "Messages";
export const ADS_SERVICE = "Ads";
export const OFFERS_SERVICE = "Offers";
export const CAREER_SERVICE = "Career";
export const TESTIMONIALS_SERVICE = "Testimonials";
export const PRODUCTS_SERVICE = "Products";
export const PRICINGS_SERVICE = "Product Pricings";
export const NEWS_SERVICE = "News";
export const BLOGS_SERVICE = "Blogs";

export const SERVICES_WITH_CLAIMS = [
  USER_MANAGEMENT_SERVICE,
  MESSAGES_SERVICE,
  ADS_SERVICE,
  OFFERS_SERVICE,
  CAREER_SERVICE,
  TESTIMONIALS_SERVICE,
  PRODUCTS_SERVICE,
  PRICINGS_SERVICE,
  NEWS_SERVICE,
  BLOGS_SERVICE,
];

export const USER_CLAIMS = ["Read", "Write", "None"];

export const MAX_DATE = new Date(9999, 11, 30);

export const CAREER_ROLES = [
  {
    category: "Administrative Role",
    roles: [
      "Accountant (2- more Years of Experience)",
      "Logistics Executive",
      "Back office Work",
      "Customer Service Representative (Tele- caller)",
      "Sales Representative",
      "Marketing Manager",
      "Purchase Manager (Preferred if work in Chemical Industry)",
      "HR Executive (2 – More years of Experience)",
    ],
  },
  {
    category: "Digital Marketing Roles",
    roles: [
      "Social Media Marketing (1-  More Year of experience)",
      "Social Media Analytics",
      "Content Writer (1- More Years of Experience)",
      "SEO Specialist",
      "Brand Management",
      "Project Manager (Preferred if from IT Background)",
      "Data Base Manager",
      "Creative Executive",
      "Conversational Marketing Manager",
      "Paid -advertising Manager",
      "Digital PR Manager",
    ],
  },
];

export const OIL_PRICE_API_KEY = "0eca4ab88f1930988be33912a21ffa17";
export const APILAYER_API_KEY = "WzXohpNM3t5BQcaY7pVBj9iGYhKrjgAl";
export const COMPANY_NAME = "BKM Solvents Pvt Ltd";
export const DEFAULT_GRID_PAGE_SIZE = 25;
