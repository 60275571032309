import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

import "./NotFound.scss";
import { ADMIN_URI } from "../../constants";

/**
 *
 * @param props
 * @returns Common Error Page For 404 Errors
 */
export function NotFound() {
  let navigate = useNavigate();

  const handleHomeClick = useCallback(() => {
    const isAdmin = window.location.pathname.startsWith(ADMIN_URI);
    isAdmin ? navigate(ADMIN_URI) : navigate("/");
  }, [navigate]);

  return (
    <div className="cep-not-found">
      <div className="cep-not-found__container">
        <div className="cep-error-code-wrapper">
          <h1 className="cep-error-code">
            4<span className="cep-error-code-0">0</span>4
          </h1>
        </div>
        <p className="cep-not-found__message">
          The page you are looking for might have been removed or had its name
          changed or is temporarily unavailable.
        </p>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<ArrowBackIcon />}
          onClick={handleHomeClick}
        >
          Go Back To Home Page
        </Button>
      </div>
    </div>
  );
}
