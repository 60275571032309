import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useMemo } from "react";
import { Backdrop, Typography } from "@mui/material";
import classNames from "classnames";

import "./Spinner.scss";

export interface SpinnerProp {
  useBackdrop?: boolean;
  size?: number;
  /**do not align it to center */
  disableAlignment?: boolean;
  text?: string;
}

export function Spinner(props: SpinnerProp) {
  const { useBackdrop, size, disableAlignment, text } = props;
  const progress = useMemo(
    () => <CircularProgress disableShrink size={size || 40} />,
    [size]
  );

  const spinnerText = useMemo(
    () => <Typography className="cep-spinner__text">{text}</Typography>,
    [text]
  );

  if (useBackdrop)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        className="cep-spinner cep-no-print"
      >
        {progress}
        {spinnerText}
      </Backdrop>
    );

  const classes = classNames("cep-spinner", "cep-no-print", {
    "cep-spinner--centered": !disableAlignment,
  });

  return (
    <Box className={classes}>
      {progress}
      {spinnerText}
    </Box>
  );
}
