import { NotAuthorized } from "../Feedback/Errors/NotAuthorized";
import { useClaims } from "../Hooks/useClaims";

export type AuthorizedRouteProp = {
  serviceName: string;
  children: React.ReactNode;
};

export function AuthorizedRoute(props: AuthorizedRouteProp) {
  const { serviceName, children } = props;
  const claim = useClaims(serviceName);

  const notAuthorized = !claim || claim.toLowerCase() === "none";

  if (notAuthorized) return <NotAuthorized />;

  return children;
}
