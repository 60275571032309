import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

export function useDeviceValidator() {
  const { oidcUser, logout } = useReactOidc();

  const getDeviceId = useCallback(() => {
    let deviceId = localStorage.getItem("deviceid");
    // if not id found then create and store one
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem("deviceid", deviceId);
    }
    return deviceId;
  }, []);

  const verifyMobileDeviceId = useCallback(async () => {
    if (!oidcUser) return;
    const deviceId = getDeviceId();

    fetch(
      `${process.env.REACT_APP_IDENTITY_BASE_URL}/Device/WebDevice?deviceId=${deviceId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${oidcUser.access_token}`,
        },
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          return Promise.reject(response);
        } else {
          return Promise.resolve(false);
        }
      })
      .catch(async (response) => {
        const data = await response.json();
        const userLocked = ((data as string) || "")
          .toLowerCase()
          .includes("subscription expired or device limit exhausted");
        if (userLocked) {
          logout();
          alert("Subscription expired or device limit exhausted");
        }
        return Promise.resolve(userLocked);
      });
  }, [getDeviceId, logout, oidcUser]);

  useEffect(() => {
    verifyMobileDeviceId();
  }, [verifyMobileDeviceId]);
}
