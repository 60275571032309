const getBaseUri = () => {
  let uri: string | undefined = window.location.origin;
  if (!uri) {
    uri = process.env.REACT_APP_BASE_URL;
  }
  return uri;
};

export const oidcConfig = {
  client_id: "spa",
  redirect_uri: `${getBaseUri()}/authentication/callback`,
  response_type: "id_token token",
  post_logout_redirect_uri: `${getBaseUri()}`,
  scope: "openid profile api1 roles IdentityServerApi",
  authority: `${process.env.REACT_APP_IDENTITY_BASE_URL}`,
  silent_redirect_uri: `${getBaseUri()}/authentication/silent_callback`,
  automaticSilentRenew: false,
  loadUserInfo: true,
  triggerAuthFlow: true,
};

export const oidcAdminConfiguration = { ...oidcConfig };
oidcAdminConfiguration.redirect_uri = `${getBaseUri()}admin/authentication/callback`;
oidcAdminConfiguration.silent_redirect_uri = `${getBaseUri()}admin/authentication/silent_callback`;
