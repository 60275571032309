import { atom } from "jotai";
import {
  SnackbarProp,
  TopGainerLooserData,
  TopGainerLooserProp,
} from "./typings";

export const snackbarAtom = atom<SnackbarProp>({
  message: "",
  openSnackbar: false,
  severity: "success",
  verticalPosition: "bottom",
  horizontalPosition: "left",
});

type HomeData = {
  loading: boolean;
  error: boolean;
  data: any;
};

export const homeDataAtom = atom<HomeData>({
  loading: true,
  data: undefined,
  error: false,
});

export const newsDataAtom = atom<{ data: any }>({ data: undefined });
export const blogDataAtom = atom<{ data: any }>({ data: undefined });

/**top gainer/looser product atoms*/
export const topGainerLooserProductDataAtom = atom<any>({ data: undefined });
export const topGainerAtom = atom<TopGainerLooserData>((get) => {
  const { data } = get(topGainerLooserProductDataAtom);
  let gainer: TopGainerLooserProp[] = [];
  let looser: TopGainerLooserProp[] = [];
  if (data && data.dailyPriceQueries) {
    const { dailyPriceQueries } = data;
    if (dailyPriceQueries) {
      ({ gainer, looser } = dailyPriceQueries);
      gainer = gainer.filter((g) => g.priceGap);
      looser = looser.filter((g) => g.priceGap);
    }
  }
  return { gainer, looser };
});
