import { OidcSecure } from "@axa-fr/react-oidc-context";
import { lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { NotFound } from "./components/Feedback/Errors/NotFound";
import { Dashboard } from "./components/Admin/Drawer/Dashboard";
import { WithSuspense } from "./WithSuspense";
import {
  ADS_SERVICE,
  BLOGS_SERVICE,
  NEWS_SERVICE,
  PRICINGS_SERVICE,
  PRODUCTS_SERVICE,
  TESTIMONIALS_SERVICE,
  USER_MANAGEMENT_SERVICE,
} from "./components/constants";
import { useDeviceValidator } from "./components/Hooks/useDeviceValidator";

/** User routes */
const UserHome = lazy(() => import("./components/User/Home/Home"));
const AboutUs = lazy(() => import("./components/User/AboutUs/AboutUs"));
const MobileApp = lazy(() => import("./components/User/MobileApp/MobileApp"));
const Careers = lazy(() => import("./components/User/Careers/Careers"));
const Terms = lazy(() => import("./components/User/Footer/Terms"));
const PrivacyPolicy = lazy(
  () => import("./components/User/Footer/PrivacyPolicy")
);
const FAQ = lazy(() => import("./components/User/FAQ/FAQ"));
const PricingPlan = lazy(
  () => import("./components/User/PricingPlans/PricingPlan")
);
const MyAccount = lazy(() => import("./components/Account/MyAccount"));
const SellEnquiryContainer = lazy(
  () => import("./components/User/Enquiry/Sell/SellEnquiryContainer")
);
const BuyEnquiryContainer = lazy(
  () => import("./components/User/Enquiry/Buy/BuyEnquiryContainer")
);
const ContactUs = lazy(() => import("./components/User/ContactUs/ContactUs"));
const Product = lazy(() => import("./components/User/Product/Product"));
const NewsByCategory = lazy(
  () => import("./components/User/News/NewsByCategory")
);
const NewsDetails = lazy(() => import("./components/User/News/NewsDetails"));
const NewsContainer = lazy(
  () => import("./components/User/News/NewsContainer")
);
const BlogsForCategory = lazy(
  () => import("./components/User/Blog/BlogsForCategory")
);
const BlogDetails = lazy(() => import("./components/User/Blog/BlogDetails"));
const BlogsContainer = lazy(
  () => import("./components/User/Blog/BlogsContainer")
);
const PlasticDrums = lazy(
  () => import("./components/User/Services/PlasticDrums/PlasticDrums")
);
const Transportation = lazy(
  () => import("./components/User/Services/Transportation/Transportation")
);
const DailyPrice = lazy(() => import("./components/User/Pricing/DailyPrice"));
const LocalPrice = lazy(() => import("./components/User/Pricing/LocalPrice"));
const InternationalPrice = lazy(
  () => import("./components/User/Pricing/InternationalPrice")
);
const DrumPrice = lazy(() => import("./components/User/Pricing/DrumPrice"));
const UserApp = lazy(() => import("./components/User/UserLayout"));

/** Admin route */
const UsersContainer = lazy(
  () => import("./components/Admin/UserManagement/UsersContainer")
);
const Clients = lazy(() => import("./components/Admin/Drawer/Clients"));
const InternationalPriceSectionAdverts = lazy(
  () => import("./components/Admin/Adverts/InternationalPriceSectionAdverts")
);
const LocalPriceSectionAdverts = lazy(
  () => import("./components/Admin/Adverts/LocalPriceSectionAdverts")
);
const DrumPriceSectionAdverts = lazy(
  () => import("./components/Admin/Adverts/DrumPriceSectionAdverts")
);
const DailyPriceSectionAdverts = lazy(
  () => import("./components/Admin/Adverts/DailyPriceSectionAdverts")
);
const UPIDetailsGrid = lazy(
  () => import("./components/Admin/UPIDetails/UPIDetails")
);
const BankDetailsGrid = lazy(
  () => import("./components/Admin/BankDetails/BankDetails")
);
const Testimonial = lazy(
  () => import("./components/Admin/Testimonial/Testimonial")
);
const Packaging = lazy(() => import("./components/Admin/Packaging/Packaging"));
const DeliveryLocation = lazy(
  () => import("./components/Admin/Transportation/DeliveryLocation")
);
const PopupBannerGrid = lazy(
  () => import("./components/Admin/Banner/PopupBanner")
);
const TransportIn = lazy(
  () => import("./components/Admin/Transportation/TransportIn")
);
const TransportationGrid = lazy(
  () => import("./components/Admin/Transportation/Transportation")
);
const FaqGrid = lazy(() => import("./components/Admin/faq/faq"));
const InternationalLocation = lazy(
  () => import("./components/Admin/Location/InternationalLocation")
);
const LocalLocation = lazy(
  () => import("./components/Admin/Location/LocalLocation")
);
const DrumLocation = lazy(
  () => import("./components/Admin/Location/DrumLocation")
);
const DailyLocation = lazy(
  () => import("./components/Admin/Location/DailyLocation")
);
const Subscription = lazy(
  () => import("./components/Admin/Subscription/Subscription")
);
const Association = lazy(
  () => import("./components/Admin/Association/Association")
);
const IndustryType = lazy(
  () => import("./components/Admin/IndustryType/IndustryType")
);
const TaxType = lazy(() => import("./components/Admin/TaxType/TaxType"));
const DealsOfTheDay = lazy(
  () => import("./components/Admin/DealOfTheDay/DealsOfTheDay")
);
const BuyEnquiries = lazy(
  () => import("./components/Admin/Enquiry/Buy/BuyEnquiries")
);
const SellEnquiries = lazy(
  () => import("./components/Admin/Enquiry/Sell/SellEnquiries")
);
const ContactUslEnquiries = lazy(
  () => import("./components/Admin/Enquiry/ContactUs/ContactUs")
);
const Origin = lazy(() => import("./components/Admin/Origin/Origin"));
const MeasureUnit = lazy(
  () => import("./components/Admin/MeasureUnit/MeasureUnit")
);
const ProductGrid = lazy(() => import("./components/Admin/Product/Product"));
const ProductTypes = lazy(
  () => import("./components/Admin/Product/ProductType/ProductTypes")
);
const AdminNews = lazy(() => import("./components/Admin/News/News"));
const InternationalPrices = lazy(
  () =>
    import("./components/Admin/PriceDetails/International/InternationalPrices")
);
const DailyPrices = lazy(
  () => import("./components/Admin/PriceDetails/Daily/DailyPrices")
);
const DrumPrices = lazy(
  () => import("./components/Admin/PriceDetails/Drum/DrumPrices")
);
const LocalPrices = lazy(
  () => import("./components/Admin/PriceDetails/Local/LocalPrices")
);
const AdminBlogs = lazy(() => import("./components/Admin/Blogs/Blogs"));
const NewsCategories = lazy(
  () => import("./components/Admin/News/Category/NewsCategories")
);
const BlogCategories = lazy(
  () => import("./components/Admin/Blogs/Category/BlogCategories")
);
const UserDetails = lazy(
  () => import("./components/Admin/UserManagement/UserDetails/UserDetails")
);
const AdminLayout = lazy(() => import("./components/Admin/AdminLayout"));

export function AppRoutes() {
  useDeviceValidator();
  /**
   * DO NOT YOU DARE TO TOUCH THIS FILE,
   * IT MIGHT BREAK ALL THE ROUTING
   */

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/admin"
          element={
            <WithSuspense>
              <OidcSecure>
                <AdminLayout />
              </OidcSecure>
            </WithSuspense>
          }
        >
          <Route index element={<Dashboard />} />
          <Route
            path="users"
            element={
              <WithSuspense serviceName={USER_MANAGEMENT_SERVICE}>
                <UsersContainer />
              </WithSuspense>
            }
          />
          <Route
            path="users/:accountId"
            element={
              <WithSuspense serviceName={USER_MANAGEMENT_SERVICE}>
                <UserDetails />
              </WithSuspense>
            }
          />
          <Route
            path="clients"
            element={
              <WithSuspense>
                <Clients />
              </WithSuspense>
            }
          />
          <Route
            path="categories/blog"
            element={
              <WithSuspense>
                <BlogCategories />
              </WithSuspense>
            }
          />
          <Route
            path="categories/news"
            element={
              <WithSuspense>
                <NewsCategories />
              </WithSuspense>
            }
          />
          <Route
            path="blogs"
            element={
              <WithSuspense serviceName={BLOGS_SERVICE}>
                <AdminBlogs />
              </WithSuspense>
            }
          />
          <Route
            path="news"
            element={
              <WithSuspense serviceName={NEWS_SERVICE}>
                <AdminNews />
              </WithSuspense>
            }
          />
          <Route
            path="drum-prices"
            element={
              <WithSuspense serviceName={PRICINGS_SERVICE}>
                <DrumPrices />
              </WithSuspense>
            }
          />
          <Route
            path="local-prices"
            element={
              <WithSuspense serviceName={PRICINGS_SERVICE}>
                <LocalPrices />
              </WithSuspense>
            }
          />
          <Route
            path="daily-prices"
            element={
              <WithSuspense serviceName={PRICINGS_SERVICE}>
                <DailyPrices />
              </WithSuspense>
            }
          />
          <Route
            path="international-prices"
            element={
              <WithSuspense serviceName={PRICINGS_SERVICE}>
                <InternationalPrices />
              </WithSuspense>
            }
          />
          <Route
            path="products"
            element={
              <WithSuspense serviceName={PRODUCTS_SERVICE}>
                <ProductGrid />
              </WithSuspense>
            }
          />
          <Route
            path="product-types"
            element={
              <WithSuspense>
                <ProductTypes />
              </WithSuspense>
            }
          />
          <Route
            path="measureUnit"
            element={
              <WithSuspense>
                <MeasureUnit />
              </WithSuspense>
            }
          />
          <Route
            path="origin"
            element={
              <WithSuspense>
                <Origin />
              </WithSuspense>
            }
          />
          <Route
            path="enquiries/buy"
            element={
              <WithSuspense>
                <BuyEnquiries />
              </WithSuspense>
            }
          />
          <Route
            path="enquiries/sell"
            element={
              <WithSuspense>
                <SellEnquiries />
              </WithSuspense>
            }
          />
          <Route
            path="enquiries/contactUs"
            element={
              <WithSuspense>
                <ContactUslEnquiries />
              </WithSuspense>
            }
          />
          <Route
            path="dealOfTheDay"
            element={
              <WithSuspense>
                <DealsOfTheDay />
              </WithSuspense>
            }
          />
          <Route
            path="taxType"
            element={
              <WithSuspense>
                <TaxType />
              </WithSuspense>
            }
          />
          <Route
            path="industry-type"
            element={
              <WithSuspense>
                <IndustryType />
              </WithSuspense>
            }
          />
          <Route
            path="association"
            element={
              <WithSuspense>
                <Association />
              </WithSuspense>
            }
          />
          <Route
            path="subscription"
            element={
              <WithSuspense>
                <Subscription />
              </WithSuspense>
            }
          />
          <Route
            path="location/daily"
            element={
              <WithSuspense>
                <DailyLocation />
              </WithSuspense>
            }
          />
          <Route
            path="location/drum"
            element={
              <WithSuspense>
                <DrumLocation />
              </WithSuspense>
            }
          />
          <Route
            path="location/local"
            element={
              <WithSuspense>
                <LocalLocation />
              </WithSuspense>
            }
          />
          <Route
            path="location/international"
            element={
              <WithSuspense>
                <InternationalLocation />
              </WithSuspense>
            }
          />
          <Route
            path="faq"
            element={
              <WithSuspense>
                <FaqGrid />
              </WithSuspense>
            }
          />
          <Route
            path="transportations"
            element={
              <WithSuspense>
                <TransportationGrid />
              </WithSuspense>
            }
          />
          <Route
            path="portLocation"
            element={
              <WithSuspense>
                <TransportIn />
              </WithSuspense>
            }
          />
          <Route
            path="banner"
            element={
              <WithSuspense>
                <PopupBannerGrid />
              </WithSuspense>
            }
          />
          <Route
            path="deliveryLocation"
            element={
              <WithSuspense>
                <DeliveryLocation />
              </WithSuspense>
            }
          />
          <Route
            path="packaging"
            element={
              <WithSuspense>
                <Packaging />
              </WithSuspense>
            }
          />
          <Route
            path="testimonial"
            element={
              <WithSuspense serviceName={TESTIMONIALS_SERVICE}>
                <Testimonial />
              </WithSuspense>
            }
          />
          <Route
            path="bank-details"
            element={
              <WithSuspense>
                <BankDetailsGrid />
              </WithSuspense>
            }
          />
          <Route
            path="upi-details"
            element={
              <WithSuspense>
                <UPIDetailsGrid />
              </WithSuspense>
            }
          />
          <Route
            path="adverts/daily-price"
            element={
              <WithSuspense serviceName={ADS_SERVICE}>
                <DailyPriceSectionAdverts />
              </WithSuspense>
            }
          />
          <Route
            path="adverts/drum-price"
            element={
              <WithSuspense serviceName={ADS_SERVICE}>
                <DrumPriceSectionAdverts />
              </WithSuspense>
            }
          />
          <Route
            path="adverts/local-price"
            element={
              <WithSuspense serviceName={ADS_SERVICE}>
                <LocalPriceSectionAdverts />
              </WithSuspense>
            }
          />
          <Route
            path="adverts/international-price"
            element={
              <WithSuspense serviceName={ADS_SERVICE}>
                <InternationalPriceSectionAdverts />
              </WithSuspense>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path="/"
          element={
            <WithSuspense>
              <UserApp />
            </WithSuspense>
          }
        >
          <Route
            index
            element={
              <WithSuspense>
                <UserHome />
              </WithSuspense>
            }
          />
          <Route
            path="pricing/daily"
            element={
              <WithSuspense>
                <DailyPrice />
              </WithSuspense>
            }
          />
          <Route
            path="pricing/local"
            element={
              <WithSuspense>
                <LocalPrice />
              </WithSuspense>
            }
          />
          <Route
            path="pricing/drum"
            element={
              <WithSuspense>
                <DrumPrice />
              </WithSuspense>
            }
          />
          <Route
            path="pricing/international"
            element={
              <WithSuspense>
                <InternationalPrice />
              </WithSuspense>
            }
          />
          <Route
            path="aboutus"
            element={
              <WithSuspense>
                <AboutUs />
              </WithSuspense>
            }
          />
          <Route
            path="services/transport"
            element={
              <WithSuspense>
                <Transportation />
              </WithSuspense>
            }
          />
          <Route
            path="services/plastic-drums"
            element={
              <WithSuspense>
                <PlasticDrums />
              </WithSuspense>
            }
          />
          <Route
            path="blogs"
            element={
              <WithSuspense>
                <BlogsContainer />
              </WithSuspense>
            }
          />
          <Route
            path="blog/:id"
            element={
              <WithSuspense>
                <BlogDetails />
              </WithSuspense>
            }
          />
          <Route
            path="blog/category/:categoryId"
            element={
              <WithSuspense>
                <BlogsForCategory />
              </WithSuspense>
            }
          />
          <Route
            path="news"
            element={
              <WithSuspense>
                <NewsContainer />
              </WithSuspense>
            }
          />
          <Route
            path="news/:id"
            element={
              <WithSuspense>
                <NewsDetails />
              </WithSuspense>
            }
          />
          <Route
            path="news/category/:categoryId"
            element={
              <WithSuspense>
                <NewsByCategory />
              </WithSuspense>
            }
          />
          <Route
            path="product/:productName"
            element={
              <WithSuspense>
                <Product />
              </WithSuspense>
            }
          />
          <Route
            path="contactus"
            element={
              <WithSuspense>
                <ContactUs />
              </WithSuspense>
            }
          />
          <Route
            path="buy"
            element={
              <WithSuspense>
                <BuyEnquiryContainer />
              </WithSuspense>
            }
          />
          <Route
            path="sell"
            element={
              <WithSuspense>
                <SellEnquiryContainer />
              </WithSuspense>
            }
          />
          <Route
            path="my-account"
            element={
              <WithSuspense>
                <MyAccount />
              </WithSuspense>
            }
          />
          <Route
            path="plans"
            element={
              <WithSuspense>
                <PricingPlan />
              </WithSuspense>
            }
          />
          <Route
            path="faq"
            element={
              <WithSuspense>
                <FAQ />
              </WithSuspense>
            }
          />
          <Route
            path="privacy-policy"
            element={
              <WithSuspense>
                <PrivacyPolicy />
              </WithSuspense>
            }
          />
          <Route
            path="terms-and-condition"
            element={
              <WithSuspense>
                <Terms />
              </WithSuspense>
            }
          />
          <Route
            path="careers"
            element={
              <WithSuspense>
                <Careers />
              </WithSuspense>
            }
          />
          <Route
            path="mobile-app"
            element={
              <WithSuspense>
                <MobileApp />
              </WithSuspense>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
