/**
 *splits camelcase string by space
 */
export const getDisplayTextFromCamelCase = (input: string) => {
  if (!input) return "";
  const result = input.replace(/([A-Z])/g, " $1");

  return result.split(" ").reduce((previousValue, currentValue, ind) => {
    if (ind === 0)
      return `${previousValue}${currentValue
        .charAt(0)
        .toUpperCase()}${currentValue.slice(1)}`;
    else if (currentValue.length === 1)
      return `${previousValue}${currentValue}`;
    else return `${previousValue} ${currentValue}`;
  }, "");
};

export const isValidEmail = (email: string) => {
  if (!email) return false;
  var mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return !!email.match(mailFormat)?.length;
};

/** convert string to camal case */
export function Camelize(str: string) {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

/** Search object property by partial name */
export const searchProperty = (nameKey: string, obj: any) => {
  if (obj[nameKey]) {
    return obj[nameKey];
  } else {
    var res = Object.keys(obj).find(function (k) {
      return (
        k.toLowerCase().indexOf(nameKey.toLowerCase()) > -1 ||
        nameKey.toLowerCase().indexOf(k.toLowerCase()) > -1
      );
    });
    return res ? obj[res] : undefined;
  }
};

/** Search object property by partial name */
export const searchKey = (nameKey: string, obj: any) => {
  const property = Object.keys(obj).find(
    (k) =>
      k.toLowerCase().indexOf(nameKey.toLowerCase()) > -1 ||
      nameKey.toLowerCase().indexOf(k.toLowerCase()) > -1
  );
  return property || "";
};

export const base64ToArrayBuffer = (base64String: string) => {
  var binary_string = window.atob(base64String);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

var BASE64_MARKER = ";base64,";

export const convertDataURIToBinary = (dataURI: string) => {
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
};
