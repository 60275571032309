import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import "./NotAuthorized.scss";

export function NotAuthorized() {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="cp-not-authorized-wrapper">
      <div className="cep-error-code-wrapper">
        <h1 className="cep-error-code">
          4<span className="cep-error-code-0">0</span>3
        </h1>
      </div>
      <div className="cp-not-authorized__message">You are not authorized.</div>
      <div className="cp-not-authorized__sub-message">
        You tried to access a page you did not have prior authorization for.
      </div>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<ArrowBackIcon />}
        onClick={handleClick}
      >
        Go Back To Main Site
      </Button>
    </div>
  );
}
