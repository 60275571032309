import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
  from,
} from "@apollo/client";
import { AuthenticationProvider } from "@axa-fr/react-oidc-context";
import { setContext } from "@apollo/client/link/context";
import { getUserManager } from "@axa-fr/react-oidc-core/dist/services";
import { useEffect } from "react";
import { onError } from "@apollo/client/link/error";
import { Provider as AtomProvider } from "jotai";

import { oidcConfig } from "./components/Utils/oidcConfig";
import { AppRoutes } from "./Routes";
import { Spinner } from "./components/Feedback/Spinner";
import { IDENTITY_CLIENT } from "./components/constants";
import { NotAuthorized } from "./components/Feedback/Errors/NotAuthorized";
import { Snackbar } from "./components/Feedback/Snackbar";
import "./App.scss";

const portalLink = createHttpLink({
  uri: "/chemicalexchange/api",
});
const identityLink = createHttpLink({
  uri: `${process.env.REACT_APP_IDENTITY_BASE_URL}/chemicalexchange-identity/api`,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem("token");
  const userManager = getUserManager();
  const oidcUser = await userManager.getUser();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization:
        oidcUser && oidcUser.access_token
          ? `Bearer ${oidcUser.access_token}`
          : "",
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

    if (networkError) console.error(`[Network error]: ${networkError}`);
  }
);

const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === IDENTITY_CLIENT,
    authLink.concat(from([errorLink, identityLink])), // <= apollo will send to this if clientName is IDENTITY_CLIENT
    authLink.concat(from([errorLink, portalLink])) // <= otherwise will send to this
  ),
  cache: new InMemoryCache(),
});

function App() {
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Disable rightclick on the website
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });

    return () => {
      document.removeEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
    };
  }, []);

  return (
    <AtomProvider>
      <AuthenticationProvider
        configuration={oidcConfig}
        isEnabled={true}
        notAuthorized={NotAuthorized}
        authenticating={() => <Spinner useBackdrop />}
        callbackComponentOverride={() => (
          <Spinner useBackdrop text="Authentication Success" />
        )}
      >
        <ApolloProvider client={client}>
          <AppRoutes />
          <Snackbar />
        </ApolloProvider>
      </AuthenticationProvider>
    </AtomProvider>
  );
}

export default App;
